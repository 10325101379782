// Breakpoints:start
export const breakpoints = {
  small: "768",
  medium: "1024",
  large: "1200",
  extraLarge: "1440",
  wide: "2000",
}
// Breakpoints:end

// Colors:start
export const colors = {
  // Insert your variables here
  white: "#FFFFFF",
  black: "#000000",
  dark: "#4C3B35",
  darkOrange: "#D67E72",
  light: "#F6EDE8",
  pink__light: "#F6EDE8",
  pink: "#EECBC9",
  blue: "#1A73E8",
  apple: "#7B9E87",
  grape: "#9F43C0",
  mustard: "#DDA15E",
}
// Colors:end

// Font Families:start
export const fonts = {
  primary: "IvyPresto Display",
  secondary: "Source Sans Pro",
}
// Font Families:end

// Font Weights:start
export const weights = {
  400: "400",
  500: "500",
  600: "600",
  700: "700",
}
// Font Weights:end
