import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import { ButtonExternalLink } from "components/button"

const StyledFooter = styled.footer`
  padding: 48px 0;
  text-align: center;

  ${breakpoint.medium`
    padding: 80px 0;
  `}
`

const Footer = () => (
  <StyledFooter className="bg--pink__light">
    <Container>
      <h2 className="mb-3">Liked what you saw?</h2>
      <ButtonExternalLink href="mailto:contacto@tomasperalta.com.ar">
        Get in touch
      </ButtonExternalLink>
    </Container>
  </StyledFooter>
)

export default Footer
