import React from "react"

// Libraries
import PropTypes from "prop-types"

// Styles
import "assets/styles/reset.css"
import GlobalStyles from "assets/styles/globalStyles"

// Components
import Seo from "components/seo/"
import Footer from "components/footer/"

const Layout = ({ children }) => (
  <main>
    <Seo />
    <GlobalStyles />
    {children}
    <Footer />
  </main>
)

Layout.propTypes = {
  children: PropTypes.element,
}

Layout.defaultProps = {
  children: null,
}

export default Layout
