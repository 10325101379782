// Libraries
import { createGlobalStyle } from "styled-components"

// Styles
import "./styles.css"
import "./bootstrap.scss"

// Utils
import { colors, fonts } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Helpers
import Helpers from "./helpers"

const GlobalStyles = createGlobalStyle`
  body {
    background-color: #FAFAFA;
    color: ${colors.dark};
    font-family: ${fonts.secondary};
    font-size: 18px;
    line-height: 1.6rem;
    
    ${breakpoint.medium`
      font-size: 20px;
    `}

    h1,
    h2,
    h3 {
      font-family: ${fonts.primary};
      font-weight: 600;
      line-height: 1.13em;
    }

    h1 {
      font-size: 48px;

      ${breakpoint.medium`
        font-size: 64px;
      `}
    }

    h2 {
      font-size: 32px;

      ${breakpoint.medium`
        font-size: 48px;
      `}
    }

    p {
      &.paragraph--small {
        font-size: 18px;
        line-height: 1.4em;
      }
    }
    
    a {
      text-decoration: none;
    }
  }

  ${Helpers}
`

export default GlobalStyles
