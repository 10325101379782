// Libraries
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import { colors } from "utils/variables/"

const ButtonStyles = `
  display: inline-block;
  padding: 10px 18px;
  color: ${colors.darkOrange};
  font-weight: 600;
  border: 2px solid ${colors.darkOrange};
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  
  &:active,
  &:hover {
    background-color: ${colors.darkOrange};
    color: ${colors.white};
  }
`

export const ButtonLink = styled(Link)`
  ${ButtonStyles}
`

export const ButtonExternalLink = styled.a`
  ${ButtonStyles}
`
